/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */
/* stylelint-disable declaration-no-important */

.CartGiftCode {
    @include mobile {
        width: 100%;
    }

    &-Actions {
        display: flex;
        justify-content: center;
        gap: 10px;

        .CartGiftCode-BalanceButton {
            background-color: #999 !important;
            margin-top: 0 !important;
            height: 35px !important;
            line-height: 30px !important;
        }
    }

    &-Message {
        display: inline-block;
    }

    &-Input {
        display: inline-block;

        input {
            width: 100%;
        }

        @include mobile {
            width: 100%;
            margin-block-start: 0 !important;
        }
    }

    &-Button {
        margin-left: 2rem;

        @include mobile {
            width: 100%;
            margin-top: 1rem;
            margin-left: 0;
        }
    }
}

.GiftCode_Input_UI {
    display: flex;
    justify-content: center;

    @include mobile {
        flex-direction: column;
        gap: 12px;
    }
}
