/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */

div .CartOverlay-ContentWrapper {
    @include desktop {
        background: #fff;

        &-Actions {
            padding: 8px 16px 16px 16px;
            background: var(--cart-overlay-totals-background);
        }

        .CartItem-Wrapper_isMobileLayout {
            min-height: 85px;
        }

        .CartItem-Price_isCartOverlay data {
            font-size: inherit;
            font-weight: 400;
        }

        .CartOverlay-Actions .Button {
            height: auto;
            border-radius: 999px;

            &.CartOverlay-CheckoutButton {
                margin-left: 20px;

                .LockIcon {
                    width: 16px;
                    margin-inline-end: 3px;
                }
            }
        }

        .CartItem_isCartOverlay {
            position: static;
        }

        .CartOverlay {
            &-ActionsAdditional {
                margin: 0 15px 10px 15px;
                padding-block-end: 10px;

                .strike {
                    display: block;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    padding-block-end: 5px;
                }

                .strike > span {
                    position: relative;
                    display: inline-block;
                }

                .strike > span::before,
                .strike > span::after {
                    content: "";
                    position: absolute;
                    inset-block-start: 50%;
                    width: 9999px;
                    height: 1px;
                    background: var(--primary-divider-color);
                }

                .strike > span::before {
                    right: 100%;
                    margin-inline-end: 15px;
                }

                .strike > span::after {
                    inset-inline-start: 100%;
                    margin-left: 15px;
                }
            }
        }
    }

    button.GnA-pay {
        background: #000;
        display: block;
        margin: 10px auto 0;
        height: 45px;
        width: 255px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 55px;

        &.Google-pay {
            background-image: url(../../images/payment_icons/g-pay.svg);
        }

        &.Apple-pay {
            background-image: url(../../images/payment_icons/apple-pay.svg);
        }
    }
}

.CartPage .CartPage-Wrapper {
    padding: 0;
}

.MiniCart div.Loader {
    position: fixed;
    z-index: 999;
}
