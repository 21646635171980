/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-no-important */

#popup-root {
    .popup-overlay {
        z-index: 999999999 !important;

        #popup-parent-product {
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            gap: 10px;
            margin-block-end: 15px;

            .ProductCardText {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: left;
            }

            img {
                max-width: 90px;
                border-radius: 5px;
            }

            &.ProductCard-Name {
                font-size: 18px;
                font-family: regular-bold, sans-serif;
                margin-block-start: 0;
                min-height: 0;

                @include desktop {
                    font-size: 22px;
                    line-height: 1.1;
                }
            }

            &.ProductCard-Name div {
                font-size: 14px;
                color: inherit;

                @include desktop {
                    font-size: 16px;
                    margin-block-end: 0;
                }
            }
        }
    }
}
