/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */

.CartItem {
    .ProductPrice {
        line-height: 1;
        text-align: right;
    }

    &-ProductActions_isMobileLayout {
        position: static;
        padding-top: 4px;
    }

    &-CartItemRows {
        justify-content: unset;
    }

    &-QuantityWrapper_isCartOverlay {
        font-size: 12px;
        font-weight: normal;
        color: var(--text-gray-color) !important;

        strong {
            font-weight: normal;
        }
    }

    .CartItem-Options {
        color: var(--text-gray-color);

        &:last-child {
            margin-block-end: 2px;
        }
    }

    &:last-child {
        border-bottom: none;
    }

    &-Wrapper {
        grid-template-columns: minmax(100px, 5fr) 2fr 2fr 1fr;

        &_isMobileLayout {
            grid-template-columns: 64px auto;
        }

        @include mobile {
            grid-template-columns: 64px auto;
        }

        &_isCart {
            @include mobile {
                grid-template-columns: 70% 15% 15%;
                grid-gap: 0;

                .CartItem-Title {
                    width: 100%;
                }

                .CartItem-ProductUnitPrice {
                    display: none;
                }
            }
        }

        &:hover {
            color: var(--color-black);
        }
    }

    &-Wrapper_isSummary {
        grid-template-columns: 60px auto;
    }

    &-Picture {
        width: 60px;
        height: 60px;
    }

    &-TaxLine {
        font-size: 12px;
        font-family: regular-book, sans-serif;
        font-weight: normal;
        text-align: end;
    }

    &-Additionals {
        .separator {
            margin: 0 8px;
            color: #CCC;

            &::before {
                content: '|';
            }
        }
    }

    &-Sku, &-Brand {
        font-size: 12px;
        font-weight: normal;
        margin: 5px 0;
        color: var(--text-gray-color);
        text-transform: uppercase;
    }

    &-Quantity {
        font-size: 12px;
        font-weight: normal;
        color: var(--text-gray-color);
    }

    &-ProductUnitPrice {
        font-family: regular-bold, sans-serif !important;
        font-size: 18px;
        text-align: start;

        .CartItem-TaxLine {
            text-align: start;
        }

        &:hover {
            color: var(--color-black);
        }
    }

    .freeshippingindicator {
        &-item {
            background: var(--text-blue-color);
            padding: 5px;
            position: absolute;
            left: -7px;
            top: 10px;
            width: 25px;
            height: 25px;
            line-height: 16px;
            text-align: center;
            border-radius: 999px;

            svg {
                font-size: 12px;
            }

            z-index: 1;
        }

        &-itemtext {
            background: var(--text-blue-color);
            padding: 3px 8px;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            border-radius: 5px;
            font-size: 13px;
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            color: #fff;

            svg {
                font-size: 12px;
                color: #fff;
                margin-top: 1px;
                margin-right: 2px;
            }
        }

        &-locked {
            background: #999;
            padding: 3px 8px;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            border-radius: 5px;
            font-size: 13px;
            font-family: regular-bold, sans-serif;
            text-transform: uppercase;
            color: #fff;

            svg {
                font-size: 12px;
                color: #fff;
                margin-top: 1px;
                margin-right: 2px;
            }
        }
    }

    .CartItem-QuantityWrapper_isCartOverlay .CartItem-Qty {
        input {
            padding: 0;
            border: none;
            font-weight: 600;
        }
    }
}
