/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable declaration-no-important */
/* stylelint-disable rule-empty-line-before */
/* stylelint-disable csstools/use-logical */
/* stylelint-disable property-no-unknown */

.CartPage {
    @include mobile {
        padding-block-end: 0;

        .CartPage-Static {
            overflow: unset;
        }
    }

    @include desktop {
        // max-width: 1170px;
        width: 100%;
        margin-inline: auto;
        padding-inline: 15px;
        background-color: var(--header-background);
    }

    &-Wrapper {
        @media (min-width: 1200px) {
            max-width: 1170px;
            width: 100%;
            padding-inline: 0;
        }

        @media (min-width: 1600px) {
            max-width: 1405px;
            width: 100%;
        }
    }

    div#bred_div {
        margin-block-end: 10px;
    }

    .amazon_pay div#AmazonPayButton {
        max-width: 210px;
    }

    &-TableHead {
        display: grid;
        grid-template-columns: 5fr 2fr 2fr 1fr;

        @include mobile {
            display: none;
        }
    }

    &-Promo {
        order: 0;
        margin: 0 0 20px 0;

        @include mobile {
            padding: 0;
        }
        .CartPage-PromoBlock {
            padding: 15px 12px;
            font-family: regular-bold, sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            .CartPage-PromoText {
                display: flex;
                justify-content: flex-start;
                width: 100%;
                flex-wrap: nowrap;
                align-content: center;
                align-items: center;
                grid-gap: 15px;
                gap: 15px;

                .PromoText {
                    &-Wrapper {
                        text-align: left;

                        .promo-link {
                            font-size: 14px;
                            color: var(--text-gray);
                            font-family: regular-book, sans-serif;
                            text-decoration: underline;

                            &:hover {
                                color: var(--text-gray-color);
                                text-decoration: underline;
                            }
                        }
                        .btn {
                            margin-top: 15px;
                        }
                    }

                    &-Image {

                        svg {
                            color: #fff;
                            background: var(--text-blue-color);
                            padding: 8px;
                            width: 30px;
                            height: 30px;
                            text-align: center;
                            border-radius: 5px;
                            vertical-align: middle;
                        }
                    }

                    &-Title {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    &-Floating {
        @include desktop {
            margin-block-end: 20px;
        }
    }

    div.Loader {
        position: absolute;
        z-index: 2;
    }

    &-Heading {
        font-size: 24px;
        font-weight: 400;
        text-transform: uppercase;
        height: auto;
        color: var(--text-light-black-color);
    }

    .ProductLinks {
        border: none;
        max-width: 100%;
        padding: 0 15px;
        margin-block-end: 30px;
    }

    p.CartCoupon-Message {
        margin-block-end: 0;
    }

    .CartItem-Wrapper_isCart .CartItem-Title {
        -webkit-padding-start: 0;
    }

    .CartItem {
        &-Heading {
            font-size: 14px;
            font-weight: 600;
            white-space: normal;
            color: var(--text-dark-gray-color);

            &:hover {
                color: var(--text-red-color);
            }
        }

        &-Picture {
            margin-right: 15px;
            width: 96px;
            height: 96px;

            @include mobile {
                width: 64px;
                height: 64px;
            }
        }

        &-Wrapper {
            padding: 5px 8px;

            @include mobile {
                padding: 10px 0;
            }
        }

        &-ProductPriceWrapper {
            .CartItem-Qty {
                display: none;
            }
        }

        &-QuantityText {
            font-size: 13px;
            color: var(--text-dark-gray-color);
            font-weight: 600;
            position: relativ;
        }

        .CartItem-Qty {
            input {
                width: 40px;
                font-size: 16px;
                min-width: unset;
                font-weight: normal;
                border: none;
                padding: 0;
                
                @include mobile {
                    text-align: center;
                }
            }

            button {
                width: 22px;
                height: 22px;
                padding: 1px;
                border: 2px solid var(--text-red-color);

                svg {
                    width: 16px;
                    height: 16px;
                }

                &[disabled] {
                    opacity: .2;
                    cursor: not-allowed;
                }
            }
        }

        .CartItem-Delete {
            margin-left: 35px;
            font-size: 15px;
        }

        &-Price {
            font-size: 18px;
            font-weight: 400;
            color: var(--color-black);
            font-family: regular-bold, sans-serif;
            text-align: end;
            margin-left: auto;
        }

        &-Content {
            flex-direction: unset;
        }

        &-QuantityWrapper {
            margin-block-end: 0;
        }
    }

    .CartPage-TotalDetails {
        font-size: 1rem;
        color: var(--text-light-black-color);
        grid-row-gap: 1.5rem;
        padding: 1rem;

        @include desktop {
            margin-block-start: 15px;
        }
    }

    .CartPage-TotalDetails dd {
        color: var(--text-red-color);
        font-size: 19px;
        font-family: regular-bold, sans-serif;
    }

    .ExpandableContent {
        &-Heading {
            color: var(--text-light-black-color);
        }

        &-Content_isContentExpanded {
            opacity: 1;
            max-height: 100%;

            @include mobile {
                padding: 0 0 10px;
            }
        }

        &-Button {
            padding: 0.5rem 1rem;

            @include desktop {
                padding: 0 0 10px;
            }
        }
    }

    .CartCoupon {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        max-width: 320px;

        @include mobile {
            form {
                display: flex;
            }
        }

        &-Button {
            width: auto;
            white-space: nowrap;
            height: 38px;
            font-size: 1.1rem;
            font-family: regular-bold, sans-serif;
            border: 2px solid rgba(0, 0, 0, 0.2);
            display: inline-flex;
            align-items: center;
            background-color: var(--text-red-color);
            transition: all 0.24s linear 0s;
            color: var(--btn-text-color);
            padding: 0 16px;
            text-transform: uppercase;
            border-radius: 999px;
            margin: 0;

            &:hover {
                height: 38px;
                padding: 0 16px;
                background-color: var(--text-red-hover-color);
                color: var(--btn-text-color);
            }

            @include desktop {
                margin-left: 18px;
            }
        }

        &-Input input {
            height: 38px;
            font-size: 15px;
            border: 2px solid rgba(0, 0, 0, 0.2);
            padding: 0 8px;
            border-radius: 0;

            @include mobile {
                height: 34px;
            }

            @include desktop {
                width: 100%;
            }
        }

        @include mobile {
            max-width: 100%;
        }
    }

    .ProductLinks-List .AddToCart {
        width: auto;
    }

    .CartPage-Discount {
        @include desktop {
            margin-block-end: 25px;
        }

        @include mobile {
            .ExpandableContent-Button {
                padding: 10px 0;
            }
        }
    }

    .gift_code_wrp {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        font-weight: 600;
        font-size: 16px;
        font-family: regular-bold, sans-serif;
        border-top: 1px solid var(--primary-divider-color);
        border-bottom: 1px solid var(--primary-divider-color);

        dd {
            color: var(--text-red-color);
            font-size: 18px;
            font-family: regular-bold, sans-serif;
        }

        @include mobile {
            border-top: none;
        }

        &:empty {
            display: none;
        }
    }

    .CartPage {
        &-Summary {
            background: none !important;
            z-index: 2;
            order: 1;
            
            @include desktop {
                .CheckoutOrderSummary {
                    padding-block-end: 10px;
                    border: 1px solid var(--cart-page-divider-background);
                    background: var(--color-white);
                }
            }

            .CartItem {
                display: none;
            }
        }

        &-Total {
            padding: 1rem 1rem 0;
            font-size: 20px;
            font-family: regular-bold, sans-serif;

            dd span {
                font-family: regular-book, sans-serif;
            }
        }

        &-CheckoutButtons {
            padding: 1rem;
        }
    }

    .CartItem-SwipeToDeleteRightSide {
        font-family: regular-bold, sans-serif;
        font-size: 20px;
    }

    .CartItem-HeadingWrapper {
        width: 100%;
    }

    .ProductLinks-Title {
        font-family: regular-bold, sans-serif;
        font-weight: 400;
        text-align: center;
        padding-block-end: 18px;
        margin-block-end: 5px;
        text-transform: uppercase;
        color: var(--text-dark-gray-color);
    }

    .ProductLinks-Wrapper {
        padding-inline: 0;
        margin-block-end: 25px;
    }

    @include desktop {
        .CartPage {
            &-Wrapper {
                grid-template-columns: 1fr 30%;
                grid-template-columns: 68% 30%;
                grid-column-gap: 1.5rem;
                padding: 0;
            }

            &-TableHead {
                background: transparent;
                font-size: 14px;
                text-transform: capitalize;
                color: #444;
                padding: 10px;
                border-bottom: 1px solid var(--primary-divider-color);

                span:last-child {
                    text-align: right;
                }
            }

            &-SummaryHeading {
                font-size: 16px;
                margin-block-end: 0;
            }

            &-CheckoutButton {
                margin-block-start: 0;
                font-size: 16px;
                width: 100%;

                .LockIcon {
                    width: 16px;
                    margin-right: 8px;
                }
            }

            &-ContinueShopping {
                font-size: 15px;
            }
        }

        div.CartCoupon-Input {
            margin-block-start: 0;
            width: 100%;
        }

        .CartItem-Actions_isEditing {
            margin-block: 0px 0;
        }
    }

    .CartPage-CheckoutButton {
        width: 100%;
        border-radius: 999px;

        &:hover {
            border-radius: 999px;
        }
    }

    .CartItem-DeleteButtonText {
        display: none;
    }

    .ProductLinks-List {
        display: block;
        position: unset;
    }

    .Field.CartCoupon-Input {
        margin-block-start: 0;
    }

    @include mobile {
        .CheckoutOrderSummary-ButtonWrapper {
            left: 0;
            inset-block-end: var(--navigation-tabs-height);
            height: auto;
            background-color: var(--header-background);
            padding: 12px;
        }
    }

    div#checkout_pay {
        order: 3;
        margin-block-start: 24px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;

        @include mobile {
            margin-bottom: 15px;
            margin-left: 0;
        }
    }

    & ~ .Footer {
        @include mobile {
            padding-block-end: calc(var(--header-nav-height) + 60px);
        }
    }

    div#zipline {
        display: inline-block;
    }

    #zip-product-widget {
        font-family: regular-book, sans-serif;
        color: #333;
        width: 100%;
        border-top: 0px solid #eee;
        padding-block-start: 0px;
        margin-block-start: 15px;

        .zip-container {
            display: flex;
            cursor: pointer;

            img.zip-logo {
                width: auto;
            }

            .zip-payment-amount {
                margin-inline: 5px;
            }

            button#zip-info-link {
                border: 2px solid rgba(0, 0, 0, 0.2);
                padding: 0 8px;
            }
        }
    }

    &-Items {
        margin-bottom: 20px;
    }

    .CartOverlay-Items {
        padding: 0;
    }

    .CartItem-Wrapper_isMobileLayout {
        grid-template-columns: 96px auto;
        min-height: 85px;

        @include mobile {
            grid-template-columns: 64px auto;
        }
    }

    .CartItem-CartItemRows {
        padding: 15px 0;
    }

    &-Terms {
        color: var(--text-gray);
        font-family: regular-book, sans-serif;
        font-size: 14px;

        @include mobile {
            margin-block-end: 15px;
        }
        
        a {
            color: var(--text-gray-color);
            text-decoration: underline;

            &:hover {
                color: #222;
                text-decoration: underline;
            }
        }
    }
}

button.GnA-pay {
    background: #000;
    display: block;
    margin: 0 auto 5px;
    height: 45px;
    width: 255px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 55px;

    &.Google-pay {
        background-image: url(../../images/payment_icons/g-pay.svg);
    }

    &.Apple-pay {
        background-image: url(../../images/payment_icons/apple-pay.svg);
    }
}
