/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */

.CartPage-Discount {
    margin-bottom: 25px;

    .ExpandableContent-Content {
        display: flex;
    }

    .CartCoupon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .Field {
            margin-top: 0;
        }
        
        p {
            margin-block-end: 0;
        }

        .CartCoupon {
            &-Input {
                flex: 1;
                height: 34px;
            }
        }

        &-Button {
            font-family: regular-bold, sans-serif;
            background-color: var(--text-red-color) !important;
            color: var(--btn-text-color) !important;
            border: 2px solid rgba(0, 0, 0, 0.2);
            margin-top: 0;
            margin-left: 10px;
            line-height: 30px !important;
            padding: 0 12px !important;
            height: auto !important;
            width: auto;
            vertical-align: top;
            border-radius: 999px;
            margin-bottom: 0;

            &:hover, &:focus {
                background-color: var(--text-red-hover-color) !important;
                border-radius: 999px;
            }

            @include mobile {
                margin-block-end: 0;
            }
        }
    }
}
